(function($) {

//******************************************************************************
//* Navigation desktop
//******************************************************************************
cad_nav_desktop = {
    mouseover_delay_timeout: null,
    nav_overlay_default_min_height: 100, 

    init: function () {
        this._init_events();
    },

    _init_events: function () {

        // Level 0 click
        $("li[data-level='0'][data-has-sub='true']").click(function (event) {
            // Click should not be propagated to body
            event.preventDefault();
            event.stopPropagation();

            // Get status and id of clicked element
            var cur = $(this);
            var is_selected = cur.hasClass("selected");
            var item_id = cur.attr("data-id");

            // Reset current status
            $("li[data-level='0']").removeClass("selected");
            $("[data-level='0']").removeClass("hide_selected_icon");
            $("[data-level='1'] .element").removeClass("selected");
            $("[data-level='1']").removeClass("visible");
            $("[data-level='2']").removeClass("visible");
            
            cad_nav_desktop._refresh_overlay_height(cur);

            // Set new status if needed
            if (!is_selected) {   
                cur.addClass("selected");
                $("[data-level='0']").addClass("hide_selected_icon");
                
                $("[data-level='1'][data-parent-id='" + item_id.toString() + "']").addClass("visible");

                // Select first level 1 element if it has a child
                var first_item = $("[data-level='1'][data-parent-id='" + item_id.toString() + "'] .element:first-child");

                if (first_item.hasClass("menu-item-has-children")) {
                    first_item.addClass("selected");
                    var first_item_id = first_item.attr('data-id');
                
                    $("[data-level='2'][data-parent-id='" + first_item_id.toString() + "']").addClass("visible");
                }  
            }

            // Hide search bar
            cad_search.hideSearch();

            // Refresh overlay visibility
            cad_nav_desktop._refresh_overlay_visibility();

        });

        // Level 1 mouseover
        $("[data-level='1'] .element").mouseover(function () {
            var this_buffer = $(this);

            // Get status and id of clicked element
            var cur = this_buffer;
            var item_id = cur.attr("data-id");

            // Reset current status
            $("[data-level='1'] .element").removeClass("selected");
            $("[data-level='2']").removeClass("visible");
            
            $("[data-level='2'][data-parent-id='" + item_id.toString() + "']").addClass("visible");

            // Set new status 
            cur.addClass("selected");

            cad_nav_desktop._refresh_overlay_height(cur);

            // Refresh overlay visibility
            cad_nav_desktop._refresh_overlay_visibility();
        });

        $("li[data-level='1'] > .element > a > .arrow_right").click(function (event) {
            // Click should not be propagated to body
            event.preventDefault();
            event.stopPropagation();

            var cur = $(this);
            var is_selected = cur.hasClass("selected");
            var item_id = cur.attr("data-id");

            $("[data-level='2']").removeClass("visible");

            if (!is_selected) {
                $("[data-level='2'][data-parent-id='" + item_id.toString() + "']").addClass("visible");
            }
        });

        // Level 2 mouseover
        $("[data-level='2'] .element").mouseover(function () {
            var this_buffer = $(this);

            // Get status and id of clicked element
            var cur = this_buffer;
            var item_id = cur.attr("data-id");

            // Reset current status
            $("[data-level='2'] .element").removeClass("selected");
            $("[data-level='3']").removeClass("visible");
            
            $("[data-level='3'][data-parent-id='" + item_id.toString() + "']").addClass("visible");

            // Set new status 
            cur.addClass("selected");

            cad_nav_desktop._refresh_overlay_height(cur);

            clearTimeout(cad_nav_desktop.mouseover_delay_timeout);
        });

        // Hide overlay on body click
        $("#main_wrapper").click(function () {
            cad_nav_desktop._hide_overlay();
        });
    },

    _refresh_overlay_visibility: function () {
        if ($("li[data-level='0'].selected").length > 0) {
            $("[data-section='nav_overlay']").addClass("visible");
            $("li[data-level='0']").addClass("show_overlay");
        }
        else {
            $("[data-section='nav_overlay']").removeClass("visible");
            $("li[data-level='0']").removeClass("show_overlay");    
        }
    },

    _refresh_overlay_height: function ( $element ) {
        var default_height = cad_nav_desktop.nav_overlay_default_min_height;
        var level0_id = $element.attr('data-id');

        $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').css("min-height", 0);

        var height = $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').outerHeight(true);

        // Gather level1 ids
        $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').find('.element').each( function () {
            var level1_id = $(this).attr('data-id');
            $('[data-section="nav_overlay"] .elements_wrapper[data-level="2"][data-parent-id="' + level1_id.toString() + '"]').each( function() {
                var current_height = $(this).outerHeight();
                if (current_height > height) {
                    height = current_height;
                }
            });
        });
        
        if (height > default_height) {
            $('[data-section="nav_overlay"]').css('min-height', height + 44);

            // Minus 52 pixels for height adjustment based on the data-level-1 margins (18 + 26)
            $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').css('min-height', height - 44);

        } else if (height < default_height) {
            $('[data-section="nav_overlay"]').css('min-height', default_height);

            // Minus 52 pixels for height adjustment based on the data-level-1 margins (18 + 26)
            $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').css('min-height', default_height - 44);
        }
    },

    _hide_overlay: function () {
        $("li[data-level='0'].selected").removeClass("selected");
        cad_nav_desktop._refresh_overlay_visibility();
    }
};



//******************************************************************************
//* Navigation mobile
//******************************************************************************
cad_nav_mobile = {
    
    init: function () {        
        $("#mobile_nav").mmenu(
            {
                extensions: ["pageshadow", "positioning", "fixedElements"],
                offCanvas: { 
                    pageSelector: "#main_wrapper",
                    position: "right"                    
                }
            }
        );

        this._init_events();
    },

    _init_events: function () {
        // Change hamburger button status
        var api = $('#mobile_nav').data('mmenu');

        if ((typeof api) !== 'undefined') {       
            api.bind('opened', function () { 
                $("button.hamburger").addClass("is-active"); 
            });

            api.bind('closed', function () { 
                $("button.hamburger").removeClass("is-active");
            });

            var width = $( window ).width();

            // Close on resize
            $( window ).resize(function() {
                if ($( window ).width() !== width ) {
                    cad_nav_mobile.close();
                    width = $( window ).width();
                }
            });
        }
    },

    open: function () {
        $("#mobile_nav").data( "mmenu" ).open();        
    },

    close: function () {
        $("#mobile_nav").data( "mmenu" ).close();        
    },

    toggle: function () {
        if($('#mobile_nav').hasClass('mm-opened')) {
            this.close();
        }
        else {
            this.open();
        }
    }

};
})(jQuery); 