(function($) {

//******************************************************************************
//* Vc column
//******************************************************************************
cad_column_modification = {

    init: function () {

        this._set_custom_background_color();
        this._find_max_height_column_and_set_position_in_equal_height_row();
    },

    _set_custom_background_color: function () {
        
        $(".vc_column_container").each(function () {
            
            if ($(this).attr('data-background-color')) {
                $(this).find('>.vc_column-inner >.wpb_wrapper').css('background-color', $(this).attr('data-background-color'));
            }

        });
    },

    // Find max height column in a same height row and set it's position to static for flex box display 
    _find_max_height_column_and_set_position_in_equal_height_row: function () {
        $(".vc_row.vc_row-o-equal-height").each(function () {
            var max_height = 0;
            var max_height_element = 0;

            $(this).find('.colored_column').each(function () {
                var $inner_element = $(this).find('>.vc_column-inner >.wpb_wrapper');
                if ($inner_element.outerHeight(true) > max_height) {
                    max_height = $inner_element.outerHeight(true);
                    max_height_element = $(this);
                }
                $(this).find('>.vc_column-inner >.wpb_wrapper').css('position', 'absolute');
            });
            $(max_height_element).find('>.vc_column-inner >.wpb_wrapper').css('position', 'static');

        });
    }

};

})(jQuery); 