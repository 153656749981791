(function($) {

//******************************************************************************
//* Fixed vc row
//******************************************************************************
cad_row_modification = {

    init: function () {

        if (uaparser.getOS().name !== "iOS") {
            this.refresh();
            
            $(window).on('cad_resize_ready', function() {
                cad_row_modification.refresh();
            });
        }

        this.set_custom_background_color();
        this._scroll_down_to_next_row();
    },

    refresh: function () {

        if (window.innerWidth > BREAKPOINT_SCREEN_XS_MAX) {
            $(".fixed_row").each(function () {
                
                var container_height = $(this).height();

                var navbar_top_height = $('nav.navbar-default [data-section="navbar-top"]').height();

                var navbar_bottom_height = 0;
                var $navbar_bottom = $('nav.navbar-default [data-section="navbar-bottom"]');
                if ($navbar_bottom.is(":visible")) {
                    navbar_bottom_height = $navbar_bottom.height();
                }

                var navigation_height = navbar_top_height + navbar_bottom_height;

                var breadcrumbs_height = 0;
                if ($('.bbp-breadcrumb, .breadcrumbs:visible').length > 0) {          
                    breadcrumbs_height = $('.bbp-breadcrumb, .breadcrumbs').outerHeight();
                }

                $('.fixed_row_spacer').height(container_height);
                $('.fixed_row_container').css({'top': navigation_height + breadcrumbs_height});
                
            });
        }
    },

    set_custom_background_color: function () {
        
        $(".vc_row").each(function () {
            
            if ($(this).attr('data-background-color')) {
              $(this).css('background-color', $(this).attr('data-background-color'));
            }

        });
    },

    _scroll_down_to_next_row: function () {
        $('[data-field="scroll_down_arrow"] .icon').click(function() {

            // Get parent element
            $parent_elem = $(this).closest('.vc_row');
            if ($(this).closest('.fixed_row_container').length) {
                $parent_elem = $(this).closest('.fixed_row_container');
            }

            // Scroll to next vc row
            if ($parent_elem.nextAll('.vc_row').length) {
                $next_row = $parent_elem.nextAll('.vc_row').first();

                // Add selector class
                $next_row.addClass('scrolled_row');

                // Scroll
                $.smoothScroll({ 
                    speed: 1000,               
                    scrollTarget: '.scrolled_row',
                    offset: -$("nav.navbar").height()
                });

                // Remove selector class
                $next_row.removeClass('scrolled_row');
            }
        });
    },

};

})(jQuery); 