(function($) {

//******************************************************************************
//* Teaser carousel
//******************************************************************************
teaser_carousel = {

    init: function () {

        $(document).ready(function(){
            $('.articles').slick({
                speed: 1000,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 670,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        });
    }

};

})(jQuery);