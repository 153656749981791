(function($) {

//******************************************************************************
//* Open data privacy settings
//******************************************************************************
cad_borlabs_cookie_box = {
    init: function () {
        if (window.BorlabsCookie !== undefined) {            
            $(".open_borlabs_cookie_box").click(function (e) {                
                e.preventDefault();
                window.BorlabsCookie.showCookieBox();
            });
        }
        else {
            $(".open_borlabs_cookie_box").hide();
        }
    },
};

})(jQuery); 