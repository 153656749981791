(function($) {

//******************************************************************************
//* Testimonial carousel
//******************************************************************************
testimonial_carousel = {

    init: function () {

        $(document).ready(function(){
            $('.testimonial_carousel').slick({
                speed: 1000
            });
        });
    }

};

})(jQuery);