(function($) {


    //******************************************************************************
    //* Essential Grid
    //******************************************************************************
    cad_essential_grid = {
        block_pushstate: false,

        init: function () {
            cad_essential_grid._init_events();
            cad_essential_grid._init_event_triggers();          
            cad_essential_grid._deep_linking();
            cad_essential_grid._custom_code_after_grid_load();
            cad_essential_grid._add_category_to_url_on_filter_click();
            cad_essential_grid._dropdown_filter_on_category_select_behaviour();
            cad_essential_grid._hide_load_more_button();
        },

        _init_events: function () {
            $(window).on('cad_grid_items_changed', function() {
                cad_essential_grid._custom_code_after_grid_load();
            });

            $("body").on('DOMSubtreeModified', ".esg-loadmore", function() {
                cad_essential_grid._hide_load_more_button();
            });
        },

        _init_event_triggers: function () {

            // Trigger event when total item count is change 
            $('.esg-loadmore').click(function () {
                var current_items = cad_essential_grid._get_total_item_counts();

                var current_item_check_loadmore_interval = setInterval(function () {
                    if (cad_essential_grid._is_all_items_visible()) {
                        if (current_items !== cad_essential_grid._get_total_item_counts()) {
                            $( window ).trigger( "cad_grid_items_changed" );
                            clearInterval(current_item_check_loadmore_interval);
                        }
                    }
                }, 200);
            });

            // Trigger event when all items are visible 
            var current_item_check_interval = setInterval(function () {
                if (cad_essential_grid._is_all_items_visible()) {
                    $( window ).trigger( "cad_grid_items_changed" );
                    clearInterval(current_item_check_interval);
                }
            }, 500);
        },

        _is_all_items_visible: function () {

            if ($('.blog-article-teaser-skin .esg-loader').css("visibility") === "hidden") {
                var visible = true;

                $('.esg-grid .esg-overflowtrick ul li.isvisiblenow').each(function () {
                    if (! $(this).is(":visible") || $(this).height() <= 0) {
                        visible = false;
                    }
                });

                return true;
            } else {
                return false;
            }
        },

        _deep_linking: function() {
            var grid = jQuery('.esg-grid');
            
            setTimeout(function(){

                var url = window.location.href;

                if(!grid.length || url.search('#') === -1) { return; }
                
                var hash = url.split('#');
                hash = hash[hash.length - 1];

                if(!hash) { return; }
             
                hash = hash.toLowerCase().split(' ').join('').split('/').join('');

                if(grid.is(':visible')) {

                    var current_item_check_interval = setInterval(function () {
                        if (cad_essential_grid._is_all_items_visible()) {
                            jQuery('div[data-filter="filter-' + hash + '"]').trigger('click');
                            $('.esg-dropdown-wrapper [data-filter="filter-' + hash + '"]').addClass('selected');
                            clearInterval(current_item_check_interval);
                        }
                    }, 500);
                }

            }, 850);
        },

        _custom_code_after_grid_load: function() {
            // wait until Grid has officially loaded
            var timer = 0;
            var gridLoaded = setInterval(function() {
                if ( $('.esg-grid').length ) {
                    var currentGrid = cad_essential_grid.get_current_grid_api_name();
                    if(currentGrid.is(':visible')) {
                        clearInterval(gridLoaded);

                        cad_cut_text._prepare();
                        cad_cut_text._fit_text();
                        cad_same_height_groups.refresh();             
                    }
                }

                // timeout after 5s
                if (timer >= 5000) {
                    clearInterval(gridLoaded);
                }

                timer += 500;
            }, 500);
        },

        // Fetch current grid's API Name :: check entry 'Redraw Grid' in Essential Grid -> Settings -> API/JavaScript Settings in WP Admin
        get_current_grid_api_name: function () {
            if (typeof essapi_1 !== 'undefined') {
                currentEssapi = essapi_1;
            } else if (typeof essapi_1_1 !== 'undefined') {
                currentEssapi = essapi_1_1;
            } else if (typeof essapi_2 !== 'undefined') {
                currentEssapi = essapi_2;
            } else if (typeof essapi_2_1 !== 'undefined') {
                currentEssapi = essapi_2_1;
            } else if (typeof essapi_3 !== 'undefined') {
                currentEssapi = essapi_3;
            } else if (typeof essapi_3_1 !== 'undefined') {
                currentEssapi = essapi_3_1;
            } else if (typeof essapi_4_1 !== 'undefined') {
                currentEssapi = essapi_4_1;
            }

            return currentEssapi;
        },

        // Get total number of item in a essential grid
        _get_total_item_counts: function () {
            return $('.esg-grid .esg-overflowtrick ul > li').length;
        },

        // Add category filter in url when filter is clicked
        _add_category_to_url_on_filter_click: function() {

            $('[data-filter]').click(function(){
                var url = window.location.href;
                var hash = url.split('#');
                var filter_category = $(this).attr('data-filter').replace('filter-','');
                
                if(filter_category !== 'filterall') {
                    if (!cad_essential_grid.block_pushstate) {
                        window.history.pushState({category: "" +filter_category +""}, '','#'+filter_category);
                    }
                } else {
                    if (!cad_essential_grid.block_pushstate) {
                        window.history.pushState({category: "" +filter_category +""}, '','#');
                    }
                }
            });

            window.onpopstate = function(event) {

                cad_essential_grid.block_pushstate = true;
                setTimeout(function () {
                    cad_essential_grid.block_pushstate = false;
                }, 1000);
                    
                if(event.state !== null) {
                    var hash = event.state.category;

                    if(hash === 'filterall') {
                        $('div[data-filter="filterall"]').trigger('click');
                    } else {
                        $('div[data-filter="filter-' + hash + '"]').trigger('click'); 
                    } 
                   
                } else {
                    $('div[data-filter="filterall"]').trigger('click');
                }
                
            };
        },

        // Hide dropdown when category in filter is clicked and change placeholder text
        _dropdown_filter_on_category_select_behaviour: function() {
            $('.esg-dropdown-wrapper [data-filter]').click(function(){
                $(this).closest('.esg-dropdown-wrapper').hide();
                if($('.esg-selected-filterbutton').hasClass('hoveredfilter')){
                    $('.esg-selected-filterbutton').removeClass('hoveredfilter');
                }
                $('.esg-selected-filterbutton span').text($(this).find('span').last().text());
            });
        },

        // Hide load more button
        _hide_load_more_button: function () {
            var $load_more_button_element = $('.esg-loadmore');
            var search_text = 'No More Items for the Selected Filter';
            var load_more_text = $load_more_button_element.text();

            if (load_more_text === search_text && $load_more_button_element.is(':visible')) {
                $load_more_button_element.hide();
            } else if (load_more_text !== search_text && !$load_more_button_element.is(':visible')) {
                $load_more_button_element.show();
            }
        }
    };

})(jQuery); 