(function($) {

//******************************************************************************
//* Teaser carousel
//******************************************************************************
staff_grid = {

    init: function () {
        $(document).ready(function(){
            $('.staff_grid .staff_element[data-link-detailpage] [data-field="staff_phone"]').on('click', function () {
                event.stopPropagation();
            });
            $('.staff_grid .staff_element[data-link-detailpage] [data-field="email"]').on('click', function () {
                event.stopPropagation();
            });

            $('.staff_grid .staff_element[data-link-detailpage]').on('click', function () {
                window.open($(this).attr('data-link-detailpage'),"_self");
            });

        });
    }
};

})(jQuery);