(function($) {

//******************************************************************************
//* Trigger "Resize ready" event
//******************************************************************************
cad_resize_handling = {
    resize_timer: null,

    init: function () {
        $( window ).resize(function() {
            clearTimeout(cad_resize_handling.resize_timer);
            cad_resize_handling.resize_timer = setTimeout(function() {
                $( window ).trigger( "cad_resize_ready" );
            }, 200);
        });
    }  
};



//******************************************************************************
//* Trigger "Scroll thinned out" event
//******************************************************************************
cad_scroll_handling = {
    scroll_timer: null,
    scroll_counter: 0,

    init: function () {
    	cad_scroll_handling._init_scroll_counter();
    	cad_scroll_handling._init_scroll_timer();
    },

   _init_scroll_timer: function () {
   		$( window ).bind('scroll' , function () {
            clearTimeout(cad_scroll_handling.scroll_timer);
            cad_scroll_handling.scroll_timer = setTimeout(function() {
                $( window ).trigger( "cad_scroll_thinned_out" );
            }, 50);
        });
   	},

    _init_scroll_counter: function () {
        $( window ).bind('scroll' , function () {
            if (cad_scroll_handling.scroll_counter === 5) {
                $( window ).trigger( "cad_scroll_thinned_out" );
                cad_scroll_handling.scroll_counter = 0;
            } else {
                cad_scroll_handling.scroll_counter++;
            }
        }); 
    } 
};

})(jQuery); 