(function($) {

//******************************************************************************
//* Bim pedia
//******************************************************************************
cad_bim_pedia = {
	fuse: null,
	data_list: typeof php_to_js.bim_pedia_date_entries !== 'undefined' ? php_to_js.bim_pedia_date_entries : [],
	$list_container: $(".wpb_content_element.bim_pedia [data-section='data_table']"),

    init: function () {
    	this._init_events();
        this._init_search();
        this._update_bim_list(cad_bim_pedia.data_list);
    },

    _init_events: function () {
    	$('.wpb_content_element.bim_pedia .search').keyup(function() {
			if (this.value === '') {
				// Full list
				cad_bim_pedia._update_bim_list(cad_bim_pedia.data_list);
			} else {
				// Filtered list
				cad_bim_pedia._update_bim_list(cad_bim_pedia.fuse.search(this.value));
			}
		});
    },

    _init_search: function () {
    	//  Params for fuse search
	    var options = {
	        shouldSort: true,
	        tokenize: true,
	        threshold: 0.3,
	        location: 0,
	        distance: 100,
	        keys: [{
				name: 'headline',
				weight: 0.7
			}, {
				name: 'text',
				weight: 0.3
			}]
	    };

	    // Init Fuse search
	    cad_bim_pedia.fuse = new Fuse(cad_bim_pedia.data_list, options);
    },

    _update_bim_list: function (list) {
    	cad_bim_pedia.$list_container.empty();
    	var element_html = '';

    	// Add element to table
    	$.each(list, function (key, value) {
    		element_html = '<div data-section="data_container">' +
						        '<p data-field="headline">' + value.headline + '</p>' +
						        '<p data-field="text">' + value.text.replace(/(?:\r\n|\r|\n)/g, '<br>') + '</p>' +
						    '</div>';

	    	cad_bim_pedia.$list_container.append(element_html);
	    });
    },
};

})(jQuery);