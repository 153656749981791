(function($) {

    //******************************************************************************
    //* UAParser init
    //******************************************************************************
    uaparser = new UAParser();



    //******************************************************************************
    //* Browser and os detection
    //******************************************************************************
    cad_browser_and_os_detection = {
        init: function () {
            this._detect_ie();
            this._detect_ios();
        },

        _detect_ie: function () {
            if (uaparser.getBrowser().name === "IE") {
                $('body').addClass('cad_is_ie');
            }
            else {
                $('body').addClass('cad_is_not_ie');            
            }
        },

        _detect_ios: function () {
            if (uaparser.getOS().name === "iOS") {
                $('body').addClass('cad_is_ios');
            }
            else {
                $('body').addClass('cad_is_not_ios');            
            }
        }    
    };



    //******************************************************************************
    //* Select2 
    //******************************************************************************
    cad_select2 = {
        init: function () {
            $(".select2_with_search .gfield_select").select2();
        }
    };



    //******************************************************************************
    //* Change main-element padding if page has breadcrumbs
    //******************************************************************************
    cad_breadcrumb_controller = {
        init: function () {
            this._init_events();
        },

        _init_events: function () {
            $(window).load(function() {
                cad_breadcrumb_controller._set_breadcrumbs_padding();
            });
            $(window).on('cad_resize_ready', function() {
                cad_breadcrumb_controller._set_breadcrumbs_padding();
            });
        },

        // Set padding to main class depending on breadcrumbs
        _set_breadcrumbs_padding: function () {  
            if ($('.bbp-breadcrumb, .breadcrumbs:visible').length > 0) {          
                var breadcrumbs_height = $('.bbp-breadcrumb, .breadcrumbs').outerHeight();
                $('main.main').css('padding-top', breadcrumbs_height);
            }
        }
    };



    //******************************************************************************
    //* WOW.js init
    //******************************************************************************
    cad_wow_settings = {
        init: function() {
            var wow = new WOW(
                {
                    boxClass:     'wow',      // default
                    animateClass: 'animated', // default
                    offset:       0,          // default
                    mobile:       true,       // default
                    live:         true        // default
                });

            wow.init();
        }
    };



    //******************************************************************************
    //* Overlay Interface
    //******************************************************************************
    cad_overlay_interface = {
        current_hide_timeout: 0,

        init: function () {
            $('#scroll_up').on('click', function () {
                cad_overlay_interface._scrollToTop(); 
            });

            $(document).on('scroll', function() {
                cad_overlay_interface._refreshScrollUpVisibility(); 
            });
        }, 

        _scrollToTop: function () {
            $.smoothScroll({
                scrollTarget: '#'
            });
        },

        _refreshScrollUpVisibility: function() {
            var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if ( bodyScrollTop > 100) {
                $('#scroll_up').addClass('visible');
                clearTimeout(cad_overlay_interface.current_hide_timeout);
                cad_overlay_interface.current_hide_timeout = setTimeout(function () { 
                    $('#scroll_up').removeClass('visible');
                }, 3000);
            }
            else {
                $('#scroll_up').removeClass('visible');
            }
        },
    };



    //******************************************************************************
    //* Searchbar toggle
    //******************************************************************************
    cad_search = {
        init: function () {
            $('[data-field="search_button"]').on( 'click', function (event) {
                if ( cad_search.isSearchVisible() ) {
                    cad_search.hideSearch();
                } else {
                    cad_search.showSearch();             
                }
                event.stopPropagation();
            });

            $("body").click(function (event) {
                if (!$(event.target).closest('#search_bar').length) {
                    cad_search.hideSearch();
                }
            });
        },

        isSearchVisible: function () {
            if ($('#search_bar').hasClass('visible')) { return true; }
            else { return false; }
        },

        showSearch: function () {
            cad_nav_desktop._hide_overlay();
            $('#search_bar').addClass('visible');
            $('#search_bar input[type="search"]').focus();
        },

        hideSearch: function () {
            $('#search_bar').removeClass('visible');             
        },
    };


})(jQuery); 