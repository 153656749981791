(function($) {

//******************************************************************************
//* Same height groups
//******************************************************************************
cad_same_height_groups = {
    cache: {},

    init: function () {

        // Refresh height
        var calculate_height = setTimeout(function() {
            cad_same_height_groups.refresh();
        }, 500);

        $(window).on('cad_resize_ready', function() {
            cad_same_height_groups.refresh();
        });
    },

    refresh: function () {
        this.cache = {};
        var max_height = 0;
        var screen_size = 0;

        // Disable height
        $("[data-same-height-group]").css("height", "");
        
        $("[data-same-height-group]").each(function () {

            if ($(this).attr('data-same-height-group-always')) {
                screen_size = 0;
            } else if ($(this).attr('data-same-height-group-min-xxs')) {
                screen_size = BREAKPOINT_SCREEN_XXS_MAX;
            } else if ($(this).attr('data-same-height-group-min-sm')) {
                screen_size = BREAKPOINT_SCREEN_SM_MAX;
            } else {
                screen_size = BREAKPOINT_SCREEN_XS_MAX;
            }

            // Set equal height            
            if (window.innerWidth > screen_size) {

                // Get max height
                max_height = cad_same_height_groups._get_max_height($(this).attr("data-same-height-group"));

                // Set height
                $(this).css("height", max_height);   
            }           
        }); 

        if ( $('.esg-grid').length ) {
            var currentGrid = cad_essential_grid.get_current_grid_api_name();
            currentGrid.esquickdraw();
        }
    },

    _get_max_height: function (group) {

        // Return cached, if max height already exist in cache var
        if (this.cache[group] !== undefined) {
            return this.cache[group];
        }

        // Calculate max height of current group
        max_height = 0;
        $("[data-same-height-group='" + group +  "']").each(function () {
            if ($(this).outerHeight() > max_height) {
                max_height = $(this).outerHeight();
            }

        });

        this.cache[group] = max_height;

        return max_height;
    }
};

})(jQuery); 